'use strict'

const _ = require('lodash')
const santaTypesDefinitions = require('../../../definitions/santaTypesDefinitions')
const inlineContentMixin = require('../../../mixins/inlineContentMixin')
const scrollMixin = require('../../../mixins/scrollMixin/scrollMixin')
const skinsJson = require('../skins/skins.json')
const skinBasedComp = require('../../../mixins/skinBasedComp')
const createReactClass = require('create-react-class')

module.exports = ({defaultSkinName, compType, displayName = 'WixContainer', includeScrollMixin = false, legacyInlineContent = false, pointerEventsNone = false}) => {
    // Create ordered skins where default skin is first
    const skins = _.pick(skinsJson, [defaultSkinName, ..._.keys(skinsJson)])

    return createReactClass({
        displayName,

        mixins: [
            ...includeScrollMixin ? [scrollMixin] : [], 
            skinBasedComp(skins), 
            ...legacyInlineContent ? [] : [inlineContentMixin]
        ],

        propTypes: {
            isMeshLayoutMechanism: santaTypesDefinitions.Layout.isMeshLayoutMechanism,
            layoutContainerClassName: santaTypesDefinitions.Layout.layoutContainerClassName
        },

        statics: {
            getComponentSkins: () => skins,
            compType
        },
    
        getSkinProperties() {
            const {isMeshLayoutMechanism} = this.props
            return {
                '': {
                    style: isMeshLayoutMechanism && pointerEventsNone ? {
                        pointerEvents: 'none'
                    } : {}
                },
                loader: {
                    style: isMeshLayoutMechanism && pointerEventsNone ? {
                        pointerEvents: 'none'
                    } : {}
                },
                inlineContent: {
                    className: this.props.layoutContainerClassName,
                    style: isMeshLayoutMechanism && pointerEventsNone ? {
                        pointerEvents: 'none'
                    } : {},
                    children: legacyInlineContent ? this.props.children : this.getChildrenRenderer()
                }
            }
        }
    })
}
